.menuItem,
.activeMenuItem {
  list-style: none;
  width: fit-content;
  border: solid 1px transparent;
  cursor: url('../../assets/img/cursors/pointer-cursor.png'), pointer;
  height: fit-content;
}

.activeMenuItem::after {
  content: "";
  display: block;
  height: 2px;
  background-color: transparent;
  width: 100%;
}

.menuItem:focus-visible {
  outline: 0;
  border-color: var(--main-focus-visible-color);
}

.menuItem::after {
  content: "";
  display: block;
  height: 2px;
  background-color: transparent;
  transition: all 0.3s ease;
  width: 0;
  background-color: var(--main-focus-visible-color);
}

.menuItem:hover::after {
  width: 100%;
}