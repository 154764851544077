.switcher {
  width: 46px;
  height: 26px;
  border-radius: var(--button-border-radius);
  cursor: url("../../assets/img/cursors/pointer-cursor.png"), pointer;
  position: relative;
  /* transition: all 0.3s ease; */
  background-color: #ccc;
}

.switcher:focus-visible {
  outline: solid 1px var(--main-focus-visible-color);
}

.backgroundTextWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.backgroundText {
  transition: color 0.5s ease;
  color: white;
  font-size: 10px;
}

.switcher:hover .backgroundText {
  color: rgb(26 37 56 / 42%);
}

.switcherCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-radius: var(--button-border-radius);
  font-size: 10px;
  background-color: var(--primary-text-color);
  color: white;
  transition: all 0.3s ease;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
