.content {
  display: flex;
  flex-direction: column;
  background: url("../../assets/img/backgrounds/background.jpeg");
  background-size: cover;
  background-position: revert-layer;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  margin-top: 83px;
  align-items: center;
  overflow: hidden;
}

.container {
  max-width: 1000px;
  padding: 32px 32px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.container::-webkit-scrollbar {
  display: none;
}

@media (max-width: 426px) {
  .content {
    margin-top: 67px;
  }
}
