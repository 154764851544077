.errorWrapper {
  animation: showHide 4s ease-in-out;
  position: absolute;
  width: fit-content;
  transform: translate(-50%, -400%);
  left: 50%;
  z-index: 1;
  text-align: center;
}

.error {
  padding: 16px;
}

.errorText {
  color: var(--main-error-color);
}

@keyframes showHide {
  0% {
    transform: translate(-50%, -400%);
  }
  20% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, -50%);
  }
  80% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translateY(-50%, -400%);
  }
}
