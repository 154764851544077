.loader {
  animation: show 0.5s ease;
  width: 100vw;
  height: 96vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
