.mainLayout {
  width: 100vw;
  height: 100vh;
  animation: show 1s ease;
  background-position: center;
  display: flex;
  flex-direction: column;
  cursor: url('../../assets/img/cursors/main-cursor.png'), pointer;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
