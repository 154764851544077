.input {
    padding: 6px 8px;
    border-radius: var(--secondary-border-radius);
    background-color: var(--primary-background-color);
    color: var(--primary-text-color);
    font-size: var(--ui-font-size);
    border: var(--input-border);
    transition: all 0.3s ease;
    width: 100%;
}

.input:hover {
    border-color: var(--main-border-hover-color);
}

.input::placeholder {
    font-size: var(--ui-font-size);
    color: var(--placeholder-text-color);
    transition: all 0.3s ease;
}

.input:hover::placeholder {
    color: var(--main-border-hover-color);
}

.input:focus-visible::placeholder {
    color: var(--main-border-hover-color);
}

.input:focus {
    outline: none;
    border: var(--input-active-border);
}
