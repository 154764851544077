.container {
 display: flex;
 gap: 32px;
}

@media (max-width: 660px) {
 .container {
  flex-direction: column;
 }
 .container:first-child {
    align-items: center;
 }
 .developerText {
    display: flex;
    flex-direction: column;
    align-items: center;
 }
}
