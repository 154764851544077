.copyButton {
 position: absolute;
 right: 10px;
 top: 10px;
 cursor: url("../../assets/img/cursors/pointer-cursor.png"), pointer;
}

.copyButton:focus-visible {
 outline-color: var(--main-focus-visible-color);
 outline-offset: 1px;
 outline-style: solid;
}

.copyButton svg path {
 transition: all 0.3s ease;
}

.copyButton:hover svg path {
 fill: var(--primary-text-color);
}
