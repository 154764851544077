* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Colors */
  --main-accent-color: rgb(33, 144, 11);
  --main-white-color: #ffffff;
  --main-focus-visible-color: #dd1919;
  --main-error-color: #dd1919;

  --primary-background-color: #ffffff;
  --secondary-background-color: #ebebeb;
  --error-background-color: rgba(248, 70, 70, 0.3);

  --main-border-color:  rgba(215, 215, 215, 0.929);
  --main-border-hover-color:  rgba(33, 144, 11, 0.335);

    /* Text colors */
  --primary-text-color: rgb(26 37 56);
  --secondary-text-color: rgb(33, 144, 11);
  --placeholder-text-color: rgba(215, 215, 215, 0.929);

  /* Effects */
  --primary-shadow: 0px 0px 35px 1px #6f6e6e26;
  --error-shadow: 0px 0px 10px -4px #f5070736;

  /* Radii */
  --primary-border-radius: 4px;
  --secondary-border-radius: 2px;

  /* Font sizes */
  --main-font-size: 16px;
  --heading-font-size: 24px;
  --description-font-size: 15px;
  --notation-font-size: 13px;
  --ui-font-size: 16px;

  /* Inputs */
  --input-border: solid 1px var(--placeholder-text-color);
  --input-active-border: solid 1px var(--main-accent-color);

  /* Buttons */
  --button-primary-text: #ffffff;
  --button-primary-bg: rgb(33, 144, 11);
  --button-primary-border: none;
  --button-primary-hover-bg:  rgb(23, 125, 3);
  --button-primary-hover-shadow: 0px 1px 5px #6f6e6e66;
  --button-primary-active-shadow: inset 0px 0px 5px #00000040;

  --button-secondary-text: rgb(33, 144, 11);
  --button-secondary-hover-text: #ffffff;
  --button-secondary-bg: #ffffff;
  --button-secondary-hover-bg: rgb(33, 144, 11);
  --button-secondary-border: solid 1px rgb(33, 144, 11);
  --button-secondary-hover-shadow: 0px 1px 5px #6f6e6e66;
  --button-secondary-active-shadow: inset 0px 0px 5px #00000040;

  --button-disabled-bg: rgb(228 228 228);
  --button-disabled-color: #c7c4c4;

  --button-border-radius: 2px;
}
