.primaryPaper, .secondaryPaper {
    height: fit-content;
    box-shadow: var(--primary-shadow);
    border-radius: var(--primary-border-radius);
    animation: show 0.3s ease;
    width: 100%;
}

.primaryPaper, .errorPaper {
    width: fit-content;
    background-color: var(--primary-background-color);
}

.errorPaper {
    border-radius: var(--primary-border-radius);
    box-shadow: var(--error-shadow);
}

.secondaryPaper {
    background-color: rgba(243, 207, 207, 0.833);
}

@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

